import request from '@/utils/request'

export function getShopPage(query) {
    return request({
        url: '/ich/product/page',
        method: 'get',
        params: query
    })
}
// 获取详情
export function getDetail(id){
  return request({
    url:'/ich/product/detail/'+id,
    method: 'get',
})
}
