<template>
  <div class="live-container">
    <breadcrumb></breadcrumb>
    <div class="container-title">购·非遗</div>
    <div class="news-category-box">
      <category @change="changeCategory" :categorys="categorys"></category>
    </div>
    <div class="list-box" v-if="list.length != 0">
      <div class="list-item" v-for="(item, index) in list" :key="index" @click="toDetail(item)">
        <div class="item-image-box">
          <img class="item-image" :src="item.image" alt="">
          <!-- 标题图遮罩插槽（放播放按钮、状态等） -->
          <div style="position: absolute;top: 10px;left: 10px;">
            <!-- <slot  name="status" :item="item"></slot> -->
          </div>
          <slot name="title-img-mask" :item="item"></slot>
        </div>
        <div class="item-info">
          <div class="item-title">{{ item.keyword }}</div>
          <div class="price">
            <div class="now-price">
              <span class="unit">¥ </span>
              <span class="num">{{ item.price }}</span>
            </div>
            <div class="old-price">
              <span>原价:{{ Math.trunc(item.otPrice) }}</span>
            </div>
          </div>

          <!-- 其他信息插槽（放活动时间、活动地点、简介等） -->
          <!-- <slot :row="item"></slot> -->
        </div>
        <!-- 小三角 -->
        <div class="triangle"></div>
      </div>
    </div>
    <el-empty v-else description="暂无商品"></el-empty>
    <pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList" />

  </div>
</template>

<script>
import {
  getShopPage
} from "@/api/buy/buy";
export default {
  name: 'goufeiyi-list',
  data() {
    return {
      categoryIndex: 0,//分类下标
      pageNo: 1,//页码
      pageSize: 9,//每页条数
      total: 0,//总数量
      //资讯分类
      categorys: [{
        name: '纺织类',
        status: 13
      }, {
        name: '工艺类',
        status: 14
      }, {
        name: '乐器类',
        status: 15
      }, {
        name: '雕刻类',
        status: 16
      }, {
        name: '陶瓷类',
        status: 17
      }],
      list: []
    }
  },
  created() {
    //获取列表
    this.getList()
  },
  methods: {
    toDetail(item){
      this.$router.push({path:'/goufeiyi/detail/'+item.id})
    },
    getList() {
      let status = this.categorys[this.categoryIndex].status//当前分类
      getShopPage({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        sid: status
      }).then(res => {
        if (res.code === 0) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })

    },

    changeCategory(index) {
      this.categoryIndex = index
      this.getList()
    }
  },
}
</script>

<style lang="scss" scoped>
.live-container {
  width: 1200px;
  margin: 0 auto;

  .container-title {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: #181B1C;
    font-family: XiaoWei-Regular;
  }
}

.list-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;

  .list-item {
    width: 31.6%;
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .item-image-box {
      width: 100%;
      height: 280px;
      position: relative;

      .item-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-info {
      padding: 20px;
      background-color: rgba(230, 230, 230, 0.295);
      border: 1px solid #F4F5F7;
      .price {
        display: flex;
        align-items: flex-end;
        .now-price {
          color: #E9487A;
          .num {
            font-size: 24px;
          }

        }
        .old-price {
          margin-left: 10px;
          color: #999;
        }
      }

      .item-title {
        color: #000000;
        font-size: 18px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 5px 0;
      }

      div {
        font-size: 14px;
        color: #979797;
        line-height: 25px;
      }
    }


    .triangle {
      position: absolute;
      transition: .3s;
      opacity: 0;
      left: 10%;
      bottom: 0;
      width: 0;
      height: 0;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      z-index: 9;
    }

    .triangle::before {
      content: "";
      transition: .3s;
      position: absolute;
      opacity: 0;
      top: 2px;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      z-index: 10;
    }

    .triangle::after {
      content: "";
      transition: .3s;
      position: absolute;
      opacity: 0;
      top: 5px;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      z-index: 11;
    }


  }

  .list-item::after {
    content: "";
    transition: .3s;
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #3A8DA6;
    opacity: 0;
  }

  .list-item:hover::after {
    opacity: 1;
  }

  .list-item:hover {

    .triangle {
      opacity: 1;
      border-bottom: 10px solid #3A8DA6;
    }

    .triangle::before {
      opacity: 1;
      top: 2.5px;
      transform: translate(-50%, -50%);
      border-bottom: 8px solid #ffffff;
    }

    .triangle::after {
      opacity: 1;
      top: 5px;
      transform: translate(-50%, -50%);
      border-bottom: 5px solid #3A8DA6;
    }

  }
}

// flex布局占位
.list-box::after {
  content: "";
  width: 30%;
  height: 0;
  display: block;
}
</style>